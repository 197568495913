import React from "react"
import Map from "../components/Map/Map"
import GUI from "../tools/gui"
import { useStore, Provider, useDispatch } from "react-redux"
import { moveCameraToPosition } from "../components/Animation/CameraAnimation"

const Karte = () => {
  const store = useStore()
  const sendActionToStore = useDispatch()

  moveCameraToPosition(sendActionToStore, 3, -10, 0, 90)

  return (
    <Provider store={store}>
      <Map id="Map" />
      <GUI />
    </Provider>
  )
}

export default Karte
