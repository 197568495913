import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import Tweakpane from "tweakpane"
import { makeImmutableCopy } from "./helper"

import "../styles/gui.css"

const GUI = () => {
  // 🗄 Get Values from Store
  const CAMERA_VALUES = makeImmutableCopy(useSelector(state => state.camera))
  const MAP_VALUES = makeImmutableCopy(useSelector(state => state.map))
  const sendActionToStore = useDispatch()

  useEffect(() => {
    let GUI_PANE = null
    // 🖼 CREATE TWEAKPANE GUI
    GUI_PANE = new Tweakpane({
      container: document.getElementById("GUI"),
      title: "Project Mode",
      expanded: false,
    })

    // 🎥 Kamera Monitor
    const CameraPane = GUI_PANE.addFolder({
      title: "🎥 Camera",
    })

    if (CAMERA_VALUES.mouseControlEnabled !== undefined) {
      CameraPane.addInput(CAMERA_VALUES, "mouseControlEnabled").on(
        "change",
        value => {
          sendActionToStore({
            type: `change/camera/mouseControlEnabled`,
            payload: value,
          })
        }
      )
    }

    CameraPane.addInput(CAMERA_VALUES.position, "x")
    CameraPane.addInput(CAMERA_VALUES.position, "y")
    CameraPane.addInput(CAMERA_VALUES.position, "z")
    CameraPane.addInput(CAMERA_VALUES.position, "zoom")
    CameraPane.addInput(CAMERA_VALUES.position, "targetX")
    CameraPane.addInput(CAMERA_VALUES.position, "targetY")
    CameraPane.addInput(CAMERA_VALUES.position, "targetZ")

    const updateCurrentCameraPositionButton = CameraPane.addButton({
      title: "🔄 Get Current Camera Position",
    })
    updateCurrentCameraPositionButton.on("click", () => {
      sendActionToStore({
        type: `animation/triggerUpdateCameraPosition`,
        payload: true,
      })
    })

    const animateToCameraPositionButton = CameraPane.addButton({
      title: "🚀 Animate to Position",
    })
    animateToCameraPositionButton.on("click", () => {
      sendActionToStore({
        type: `animateCameraToPosition`,
        payload: {
          x: CAMERA_VALUES.position.x,
          y: CAMERA_VALUES.position.y,
          z: CAMERA_VALUES.position.z,
          zoom: CAMERA_VALUES.position.zoom,
          targetX: CAMERA_VALUES.position.targetX,
          targetY: CAMERA_VALUES.position.targetY,
          targetZ: CAMERA_VALUES.position.targetZ,
        },
      })
    })

    // 🗺 Map
    const MapPane = GUI_PANE.addFolder({
      title: "🗺 Map",
    })

    MapPane.addInput(MAP_VALUES, "groundColor")
    const updateMapSettingButton = MapPane.addButton({
      title: "💾 Update Map Settings",
    })
    updateMapSettingButton.on("click", () => {
      sendActionToStore({
        type: `change/map/groundColor`,
        payload: MAP_VALUES.groundColor,
      })
    })

    // 🚀 Animations
    // const AnimationPane = GUI_PANE.addFolder({
    //   title: "🚀 Animation",
    // })

    // PLACE FOR PREDEFINED ANIMATIONS TO TEST!!

    // const animateToCameraPositionButton = AnimationPane.addButton({
    //   title: "🚀 Test Animation",
    // })
    // animateToCameraPositionButton.on("click", () => {
    //   sendActionToStore({
    //     type: `animateCameraToPosition`,
    //     payload: {
    //       x: CAMERA_VALUES.position.x,
    //       y: CAMERA_VALUES.position.y,
    //       z: CAMERA_VALUES.position.z,
    //       targetX: CAMERA_VALUES.position.targetX,
    //       targetY: CAMERA_VALUES.position.targetY,
    //       targetZ: CAMERA_VALUES.position.targetZ,
    //     },
    //   })
    // })

    return () => {
      // On change Rerender the Panel
      // Maybe not the best way - but it works
      if (GUI_PANE !== null) {
        // console.log("DISPOSE")
        GUI_PANE.dispose()
      }
    }
  }, [CAMERA_VALUES, MAP_VALUES, sendActionToStore])

  return <div id="GUI"></div>
}

export default GUI
